import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import ContentBanner from '../../components/Common/ContentBanner'
import Footer from "../../components/App/Footer"
import ReceiptCampaign from '../../components/Examples/ReceiptCampaign'
import ProjectStartArea from '../../components/Common/StartProject';
import Helmet from 'react-helmet';
const Examples = () => {

  return (
	<Layout>
	  <Navbar />
	  <ContentBanner
		pageTitle="レシートキャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="レシートキャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <ReceiptCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples