import React, {useState} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import PopularExamples from './PopularExample';
import WrapperStyle from './examples.style'
import Drawer from '../../components/UiComponent/drawer'
import ScriptTag from 'react-script-tag';
import starIcon from '../../assets/images/star-icon.png';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import {Link} from 'gatsby';
import process1 from '../../assets/images/examples/receipt/ai-ocr.png';

const OurSolutions = () => {
    const [visible, setVisible] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title) => {
        setVisible(true);
        setPromotionId(id);
        setDrawerTitle(title)
    };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>レシートキャンペーン
                    </h1>
                    <p>
                        応募されたレシートはOCR専用のAIで自動判定し、<br/>応募条件に満たしたレシートだけ応募できないためチェックコストを大幅に削減できます。
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <a data-retach-widget="embed" href="https://retach.biz/promotions/650c760502379eb8fc" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                    </div>
                </div>
            </div>
        </section>
          <section className="process-area pt-100 pb-70">
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="banner" />
                        Why is The retach?
                    </span>
                      <h2>リタッチが選ばれる6つの理由</h2>
                      <p>リタッチが提供するレシートキャンペーンは、自動解析・自動応募チェックは基本機能はもちろん、キャンペーンを拡散させたりアンケートを行ったりできます。オプション料金などもありません。</p>
                  </div>

                  <div className="row">
                      <div className="col-lg-4 col-md-6">
                          <div className="single-process-box">
                              <div className="number">1</div>
                              <div className="image">
                                  <img src={process1} alt="banner" />
                              </div>
                              <h3>世界最高水準の認識率</h3>
                              <p className="text-left">リタッチのレシートのOCRに特化したAIの認識精度は、<u>文書解析と認識に関する国際会議では4分野にて世界No.1</u>を獲得しています。</p>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                          <div className="single-process-box">
                              <div className="number">2</div>
                              <div className="image">
                                  <StaticImage src={"../../assets/images/examples/receipt/twitter-share.png"} />
                              </div>
                              <h3>リツイート・いいねで拡散</h3>
                              <p className="text-left">レシートを応募するだけではありません！Twitterのリツイートやいいねで<u>レシートキャンペーンを拡散</u>してもらうことができます。</p>
                          </div>

                      </div>

                      <div className="col-lg-4 col-md-6">
                          <div className="single-process-box">
                              <div className="number">3</div>
                              <div className="image">
                                  <StaticImage src={"../../assets/images/examples/receipt/widget.png"} />
                              </div>
                              <h3>応募点数がすぐにわかる</h3>
                              <p className="text-left">応募してすぐにレシートを解析し、対象商品と数量から応募点数を表示します。応募者にとってわかりやすいインターフェースです。</p>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                          <div className="single-process-box ">
                              <div className="number">4</div>
                              <div className="image">
                                  <StaticImage src={"../../assets/images/examples/receipt/cloud.png"} />
                              </div>
                              <h3>クラウドで一括管理</h3>
                              <p className="text-left">リタッチで開催したキャンペーンを一括で管理できます。<u>過去のTwitterフォロー＆リツイートキャンペーンやアンケート</u>なども一緒に管理。</p>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                          <div className="single-process-box">
                              <div className="number">5</div>
                              <div className="image">
                                  <StaticImage src={"../../assets/images/examples/receipt/line-web.png"} />
                              </div>
                              <h3>LINEでもWEBでも応募可能</h3>
                              <p className="text-left">御社のLINE公式アカウントと連携してLINEアカウントで応募してもらうことも、ホームページで行うことも可能です。もちろん両方で開催することも可能です。</p>
                          </div>
                      </div>

                      <div className="col-lg-4 col-md-6">
                          <div className="single-process-box">
                              <div className="number">6</div>
                              <div className="image">
                                  <StaticImage src={"../../assets/images/examples/receipt/instant-win.png"} />
                              </div>
                              <h3>インスタントウィンに対応</h3>
                              <p className="text-left"><u>追加料金は不要です。</u>その場で抽選を行ってクーポンコードなどのデジタルギフトから特典画像・動画などを提供することができます。</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="about-area ptb-100">
              <div className="container-fluid">
                  <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                          <div className="about-image">
                              <StaticImage src="../../assets/images/examples/receipt/share.png" />
                          </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                          <div className="about-content">
                              <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="about" />
                                    share
                                </span>
                                  <h2>友達紹介でリード獲得</h2>
                                  <p>リタッチのレシートキャンペーンではLINE友達にキャンペーン紹介し、今まで同じ参加者ばかりのレシートキャンペーンに新しい風を吹き込むことが出来ます。</p>
                                  <ul className="content-list">
                                      <li><i className="flaticon-tick"></i> LINEで直接招待し新しい友達が増える！</li>
                                      <li><i className="flaticon-tick"></i> リッチメッセージで参加率アップ！</li>
                                      <li><i className="flaticon-tick"></i> 紹介者より友達が得をする仕組み！</li>
                                      <li><i className="flaticon-tick"></i> アンケートで趣味・属性・関心を取得できる！</li>
                                  </ul>
                                  <p>Twitterのリツイートやいいねも組み合わせると、従来のレシートキャンペーンでは多くの参加者を集めることできなかった欠点を解消できます。</p>

                                  <Link to="/examples/line/receipt" className="default-btn">
                                      <i className="flaticon-right"></i>
                                      サンプルで動作を確認する
                                      <span></span>
                                  </Link>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="our-mission-area ptb-100">
              <div className="container-fluid">
                  <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                          <div className="our-mission-content">
                              <div className="content">
                                  <span className="sub-title">
                                    <img src={starIcon} alt="about" />
                                    pricing
                                </span>
                                  <h2>初期費用なし<br/>月額98,000円でご提供</h2>
                                  <p>レシートキャンペーンはビジネスプラン（月額98,000円）でご利用頂けます。独自アルゴリズムの商品名一致判定により、レシートチェックを自動化し運用コストの大幅削減が可能です。
                                  </p>
                                  <ul className="features-list">
                                      <li><i className="flaticon-tick"></i> 応募の一元管理</li>
                                      <li><i className="flaticon-tick"></i> レシート画像の閲覧</li>
                                      <li><i className="flaticon-tick"></i> 重複レシート判定</li>
                                      <li><i className="flaticon-tick"></i> 対象商品一致率チェック</li>
                                  </ul>
                                  <p>
                                      運用・検閲作業・当選連絡・景品発送までご依頼していただくことも可能です。ご依頼される場合は<Link to={"/contact"} className="link">こちらから</Link>お問い合わせください。
                                      <br/> <small>※レシート1枚10円が追加料金が発生いたします。</small>
                                  </p>
                                  <Link to="/pricing" className="default-btn">
                                      <i className="flaticon-right"></i>
                                      料金と機能を見る <span></span>
                                  </Link>
                              </div>
                          </div>
                      </div>

                      <div className="col-lg-6 col-md-12">
                          <div className="our-mission-image">
                              <StaticImage src="../../assets/images/examples/receipt/cost.png" />
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="process-area ptb-100 bg-fafafb">
              <div className="container">
                  <div className="section-title">
                    {/*<span className="sub-title">*/}
                    {/*    <StaticImage src="../../assets/images/star-icon.png" alt="features" />*/}
                    {/*    How It's Work*/}
                    {/*</span>*/}
                      <h2>レシート応募条件の設定</h2>
                  </div>

                  <div className="row align-items-center m-0">
                      <div className={"col-lg-12"}>
                          <table className="table table-bordered">
                              <thead>
                              <tr>
                                  <th scope="col">機能</th>
                                  <th scope="col">詳細</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                  <th scope="row">1人の応募回数制限</th>
                                  <td>「1回限り」又は「なんどでも応募」が設定できます。</td>
                              </tr>
                              <tr>
                                  <th scope="row">1枚1口までの制限</th>
                                  <td>1枚のレシートので応募口数の上限を、1口までに制限できます。</td>
                              </tr>
                              <tr>
                                  <th scope="row">応募条件の基準</th>
                                  <td >「対象商品の商品数」と「対象商品の合計金額」のどちらかを基準にできます。
                                  </td>
                              </tr>
                              <tr>
                                  <th scope="row">対象商品の上限</th>
                                  <td >対象商品は30個まで指定できます。</td>
                              </tr>
                              <tr>
                                  <th scope="row">一度に応募できるレシート枚数</th>
                                  <td >1回で応募できるレシートの上限は3枚です。3枚の合計で応募点数（口数）が計算されます。
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>

                  </div>
              </div>
          </section>
          <section className="ptb-100 top-question" >
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                      <h2 id="question">よくあるご質問</h2>
                  </div>
                  <div className="faq-accordion">
                      <Accordion allowMultipleExpanded={true}>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      LINEでレシートキャンぺーンはできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。もちろん可能です。LINEアカウントで応募してもらうにはLINE公式アカウントが必要です。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      対象商品を複数個で1口とすることはできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。可能です。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      同じ商品でも単品と箱で応募口数（点数）をそれぞれに設定できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。可能です。例えばアイス単品が1口、アイス箱が5口のような設定が可能です。<br/>
                                    同じ商品名では別々の口数にすることはできません。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      応募されたレシートを見ることはできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。管理画面の応募一覧から見ることができます。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      応募者の住所や電話番号を取得できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。応募者に入力して頂く項目は自由に変更できます。応募者情報の設定については<Link to={'/documents/user'} className={"link"}>こちら</Link>をご覧ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      公式LINEアカウントと連携できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。応募時に御社の公式LINEアカウントと連携することが可能です。連携するとLINEのUserIDを取得できます。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      当選者にLINEで当選メッセージを送れますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。レシートキャンペーンをLINE連携するとLINEのUserIDが取得できますので、LINEの管理画面から一括でメッセージ送信できます。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      キャンペーンの問い合わせ対応や、抽選などの運営事務局を依頼できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。運営事務局についてはお見積りが必要となりますので、<Link to={'/contact'} className="link">お問い合わせページ</Link>からご連絡ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  </div>
              </div>
          </section>
          <PopularExamples
            title='ビジネスプランは全部使えます'
            description="レシートキャンペーンはビジネスプランに含まれているリタッチの機能の一部です。ビジネスプランでリタッチの全ての機能が使用でき、下記サンプルのような全てのキャンペーンが行えます。"
            exclude={['receipt']}
            showDrawer={showDrawer}
          />
          <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions